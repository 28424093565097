@import './src/scss/variables';
@import './src/scss/app';

.table-row-capitalization {
  background-color: lightblue;
}

.table-row-total, .table-row-year.table-row-total {
  background-color: lightgrey !important;
}

.table-row-year {
  background-color: lightgoldenrodyellow !important;
}

.table-capitalization-12, .table-capitalization-24, .table-capitalization-26, .table-capitalization-52, .table-capitalization-360  {
  .table-row-capitalization {
    background-color: unset;
  }
}